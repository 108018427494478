/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, render: Render = null, ...rest }) => {
  return (
    <Route {...rest} render={(props) => {
      if (sessionStorage.getItem('userInfos')) {
        return Render ? (Render(props)) : Component ? (<Component {...props} />) : null
      }
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />

    }} />
  )
}

export default PrivateRoute