import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const UserContext = createContext()

export const useUser = () => useContext(UserContext)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    role: 'USER', // Default role
    accessLevel: 'SITE', // Default access level
  })

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
}
UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
