/* eslint-disable prettier/prettier */
import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { UserProvider } from './components/UserContext';
import './scss/style.scss'

import axios from 'axios'
import PrivateRoute from './components/PrivateRoute'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const apiprotocol = 'http://'
const apiUrl = apiprotocol + 'localhost:8095'

axios.interceptors.request.use(
  (config) => {
    const { origin } = new URL(config.url)
    const allowedOrigins = [apiUrl]
    const token = sessionStorage.getItem('userInfos')
    if (token === null) {
      // console.log("axios.defaults.headers")
      // console.log(axios.defaults.headers)
      //delete axios.defaults.headers.common['Authorization'];
    } else {
      window.location.hash = '/'
      if (allowedOrigins.includes(origin)) {
        // config.headers.authorization = `Bearer ${token}`;
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// Pages
const Login = React.lazy(() => import('./components/login/Login'))
const RequestReset = React.lazy(() => import('./components/login/RequestReset'))
const ResetPassword = React.lazy(() => import('./components/login/ResetPassword'))
const Page403 = React.lazy(() => import('./views/error/page403/Page403'))
const Page404 = React.lazy(() => import('./views/error/page404/Page404'))
const Page500 = React.lazy(() => import('./views/error/page500/Page500'))

class App extends Component {
  render() {
    return (
      <UserProvider>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/request_password_reset"
                name="Request a password reset"
                render={(props) => <RequestReset {...props} />}
              />
              <Route
                exact
                path="/password_reset/:key/:token"
                name="Reset a password"
                render={(props) => <ResetPassword {...props} />}
              />
              <Route exact path="/403" name="Page 403" render={(props) => <Page403 {...props} />} />
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <PrivateRoute
                path={['/', '/home']}
                name="Home"
                render={(props) => <DefaultLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </UserProvider>
    )
  }
}

export default App
